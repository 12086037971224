import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import SectionAlternatingLayout from "~components/sections/section_alternating_layout/section_alternating_layout";
import SectionContactForm from "~components/sections/section_contact_form/section_contact_form";
import SectionFeatureGrid from "~components/sections/section_feature_grid/section_feature_grid";
import SectionHeroWrapper from "~components/sections/section_hero_wrapper/section_hero_wrapper";
import SectionStats from "~components/sections/section_stats/section_stats";
import renderDynamicComponent from "~components/utils/render_dynamic_component/render_dynamic_component";
import usePageData from "~hooks/use_page_data/use_page_data";
import { STORYBLOK_ENTRY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";

const TEMPLATE_COMPONENTS = {
  sectionAlternatingLayout: SectionAlternatingLayout,
  sectionContactForm: SectionContactForm,
  sectionFeatureGrid: SectionFeatureGrid,
  sectionHeroWrapper: SectionHeroWrapper,
  sectionStats: SectionStats,
};

export default function TemplateIndustryPage(props) {
  const { story, pageContext } = usePageData(props);

  const { children } = story;
  const { subNavData, lang } = pageContext;

  return (
    <TemplateGlobalEntry
      headerColor="white"
      lang={lang}
      subNavData={subNavData}
      {...props}
    >
      {children?.length > 0 &&
        children.map((childComponent) => {
          return renderDynamicComponent({
            parentComponentName: "TemplateIndustryPage",
            componentName: childComponent.component,
            componentsMap: TEMPLATE_COMPONENTS,
            ...childComponent,
          });
        })}
    </TemplateGlobalEntry>
  );
}

TemplateIndustryPage.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

TemplateIndustryPage.defaultProps = {
  pageContext: {
    subNavData: [],
  },
};
// TODO: get page specific metadata here
export const query = graphql`
  fragment story on Query {
    story: storyblokEntry(id: { eq: $id }) {
      assets {
        ...asset
      }
      content
      full_slug
      group_id
      alternates {
        full_slug
      }
    }
  }

  query TemplateIndustryPageQuery($id: String, $langRegex: String) {
    ...story
    ...global
  }
`;
